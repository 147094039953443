// Single SPA staging/development entry file
import { start, registerApplication } from 'single-spa'
import 'babel-polyfill'

import environment from './environment'
import { defaultCustomProps } from '@src/hooks/useCustomProps'

function getLifeCycles(path: string) {
  return () => window.System.import(path)
}

registerApplication({
  name: environment.appName,
  app: getLifeCycles(`/benefits-mf-margin-exceeded/${environment.appFile}.js`),
  activeWhen: () => true,
  customProps: {
    ...defaultCustomProps,
  },
})

start()
